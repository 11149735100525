// import React from 'react';
// import { BrowserRouter, withRouter, Routes } from 'react-router-dom';
// import AppRoute from './utils/AppRoute';
// import ScrollReveal from './utils/ScrollReveal';

// // Layouts
// import LayoutDefault from './layouts/LayoutDefault';
// import LayoutAlternative from './layouts/LayoutAlternative';
// import LayoutSignin from './layouts/LayoutSignin';

// // Views 
// import Home from './views/Home';
// import Secondary from './views/Secondary';
// import Login from './views/Login';
// import Signup from './views/Signup';
// import Routess from './routes';
// import store from './store/store';
// import { Provider } from 'react-redux';
// import './assets/styles/app.scss';

// class App extends React.Component {

//   componentDidMount() {
//     document.body.classList.add('is-loaded')
//     this.refs.scrollReveal.init();
//   }

//   // Route change
//   componentDidUpdate(prevProps) {
//     if (this.props.location.pathname !== prevProps.location.pathname) {
//       this.refs.scrollReveal.init();
//     }
//   }

//   render() {
//     return (
//       <>
//     {/* <BrowserRouter> */}
//       <ScrollReveal
//         ref="scrollReveal"
//         children={() => (
//           <Routes>
//             <AppRoute exact path="/" element={<Home/>} layout={LayoutDefault} />
//             <AppRoute exact path="/secondary" element={<Secondary/>} layout={LayoutAlternative} />
//             <AppRoute exact path="/login" element={<Login/>} layout={LayoutSignin} />
//             <AppRoute exact path="/register" element={<Signup/>} layout={LayoutSignin} />
//             <Provider store={store}>
//               <Routess />
//             </Provider>
//           </Routes>
//           )} /> 
//         {/* </BrowserRouter> */}
//         </>
//     );
//   }
// }

// export default withRouter(props => <App {...props} />);

import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import ScrollReveal from './utils/ScrollReveal';
import AppRoute from './utils/AppRoute';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';
import LayoutAlternative from './layouts/LayoutAlternative';
import LayoutSignin from './layouts/LayoutSignin';

// Views
import Home from './views/Home';
import Secondary from './views/Secondary';
import Login from './views/Login';
import Signup from './views/Signup';
// import Routess from './routes';
import store from './store/store';
import './assets/styles/app.scss';
import LoginPage from './app/LoginPage/LoginPage';
import ForgotPasswordPage from './app/ForgotPasswordPag/ForgotPasswordPage';
import VerifyOtpPage from './app/VerifyOtpPage/VerifyOtpPage';
import SetPasswordPage from './app/SetPasswordPage/SetPasswordPage';
import NotFound from './components/NotFound/NotFound';
import Dashboard from './app/DashboardPage/DashboardPage';
import Accounts from './app/AccountsPage/AccountsPage';
import MakeSalePage from './app/MakeSalePage/MakeSalePage';
import ProductSettingsPage from './app/ProductSettingsPage/ProductSettingsPage';
import CartsPage from './app/CartsPage/CartsPage';
import MySalesPage from './app/MySalesPage/MySalesPage';
import SupportPage from './app/SupportPage/SupportPage';
import SettingsPage from './app/SettingsPage/SettingsPage';
import ProfilePage from './app/ProfilePage/ProfilePage';
import PaymentsPage from './app/PaymentsPage/PaymentsPage';
import SalesRecordPage from './app/SalesRecordPage/SalesRecordPage';
import CategorySettingsPage from './app/CategorySettingsPage/CategorySettingsPage';
import GetStartedPage from './app/RegisterPage/GetStartedPage';

import AdminRoutesHOC from './containers/AdminRoutes'; // Example protected route
import AttendantRoutesHOC from './containers/AttendantRoutes'; // Example protected route
import SetupPage from './app/RegisterPage/SetupPage';
import AboutPage from './views/About';
import Shop from './views/Shop.';
import Contact from './views/Contact';
import Downloads from './views/Downloads';
import Returns from './views/Returns';
import Payment_options from './views/Payment';
import Terms from './views/Terms';
import Policy from './views/Policy';
import Cart from './views/Cart';
import CheckoutPage from './views/Checkout';
import Product from './views/Product';
import Orders from './views/Orders';


class App extends React.Component {
  componentDidMount() {
    document.body.classList.add('is-loaded');
    if (this.scrollReveal) {
      this.scrollReveal.init();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location?.pathname !== prevProps.location?.pathname &&
      this.scrollReveal
    ) {
      this.scrollReveal.init();
    }
  }

  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          {/* <ScrollReveal
            ref={(instance) => (this.scrollReveal = instance)}
          > */}
            <Routes>
              <Route path="/" element={<LayoutDefault/>} >
                <Route index element={<Home />} />
                <Route path='about' element={<AboutPage />} />
                <Route path='shop' element={<Shop />} />
                <Route path='product' element={<Product />} />
                <Route path='cart' element={<Cart />} />
                <Route path='orders' element={<Orders />} />
                <Route path='track-order' element={<Cart />} />
                <Route path='checkout' element={<CheckoutPage />} />
                <Route path='contact' element={<Contact />} />
                <Route path='downloads' element={<Downloads />} />
                <Route path='returns' element={<Returns />} />
                <Route path='payment_options' element={<Payment_options />} />
                <Route path='terms' element={<Terms />} />
                <Route path='privacy' element={<Policy />} />
                <Route path='faqs' element={<Policy />} />
              </Route>
              {/* <Route
                path="/secondary"
                element={<LayoutAlternative />}
              >
                <Route index element={<Secondary />} />
              </Route> */}
              <Route path="/login" element={<LayoutSignin />}>
                <Route index element={<Login />} />
              </Route>
              <Route path="/register" element={<LayoutSignin />}>
                <Route index element={<Signup />} />
              </Route>
              {/* Delegate sub-routing to Routess */}
              {/* <Route path="/*" element={<Routess />} /> */}
              <Route path="business">
                {/* Public routes */}
                <Route index  element={<LoginPage />} />
                <Route path="forgot-password" element={<ForgotPasswordPage />} />
                <Route path="register" element={<GetStartedPage />} />
                <Route path="create-account" element={<SetupPage />} />
                <Route path="verify-otp" element={<VerifyOtpPage />} />
                <Route path="set-password" element={<SetPasswordPage />} />
                <Route path='setup' element={<SetupPage />} />

                {/* Protected admin routes */}
                <Route path="dashboard" element={<AdminRoutesHOC />}>
                  <Route index element={<Dashboard />} />
                </Route>
                <Route path="account" element={<AdminRoutesHOC />}>
                  <Route index element={<ProfilePage />} />
                </Route>
                <Route path="products" element={<AdminRoutesHOC />}>
                  <Route index element={<ProductSettingsPage />} />
                </Route>
                <Route path="orders" element={<AdminRoutesHOC />}>
                  <Route index element={<SalesRecordPage />} />
                </Route>
                <Route path="payments" element={<AdminRoutesHOC />}>
                  <Route index element={<PaymentsPage  />} />
                </Route>
                <Route path="settings" element={<AdminRoutesHOC />}>
                  <Route index element={<SettingsPage />} />
                </Route>
                <Route path="support" element={<AdminRoutesHOC />}>
                  <Route index element={<SupportPage />} />
                </Route>
                  {/* <Route path="business/vendors" element={<Accounts />} /> */}
                  {/* <Route path="business/category" element={<CategorySettingsPage />} /> */}

                {/* Protected attendant routes */}
                {/* <Route path="business/" element={<AttendantRoutesHOC />}>
                  <Route path="business/make-sale" element={<MakeSalePage />} />
                  <Route path="business/my-sales" element={<MySalesPage />} />
                  <Route path="business/cart" element={<CartsPage />} />
                </Route> */}

              </Route>
              {/* Catch-all route */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          {/* </ScrollReveal> */}
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
