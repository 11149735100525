import React, { useState, useEffect } from 'react';
import PageHeader from '../components/sections/PageHeader';
import { getAllOrders, cancelOrder, confirmOrder, rateOrder, fileComplaint, changeOrderStatus } from '../network/order_api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faWarning, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import Button from '../components/elements/Button';
import '../assets/scss/core/layout/_orders.scss';

const Orders = () => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [expandedOrders, setExpandedOrders] = useState({});
    const [ratingModal, setRatingModal] = useState({ show: false, orderId: null });
    const [complaintModal, setComplaintModal] = useState({ show: false, orderId: null });

    useEffect(() => {
        fetchOrders();
    }, []);

    const fetchOrders = async () => {
        try {
            setLoading(true);
            const response1 = await getAllOrders("completed");
            const response2 = await getAllOrders("cancelled");
            const response3 = await getAllOrders("confirmed");
            const response = [
                ...(response3?.data || []),
                ...(response1?.data || []),
                ...(response2?.data || [])
            ];
            // Group orders by vendor and reference
            const groupedOrders = groupOrdersByVendorAndRef(response);
            console.log(groupedOrders)
            setOrders(groupedOrders);
            setLoading(false);
        } catch (err) {
            setError(err.message);
            setLoading(false);
        }
    };

    const groupOrdersByVendorAndRef = (ordersList) => {
        return ordersList.reduce((acc, order) => {
            const key = `${order.ref}`;
            if (!acc[key]) {
                acc[key] = {
                    vendorName: order.business.businessName,
                    referenceId: order.ref,
                    status: order.status === 'CONFIRMED' ? 'PENDING' : order.status,
                    ready: order.ready,
                    date: order.timestamp,
                    items: []
                };
            }
            acc[key].items.push(order.products);
            return acc;
        }, {});
    };

    const handleCancel = async (orderId) => {
        try {
            await changeOrderStatus(orderId,"CANCELLED");
            await fetchOrders();
        } catch (err) {
            setError(err.message);
        }
    };

    const handleConfirm = async (orderId) => {
        try {
            await changeOrderStatus(orderId,'COMPLETED');
            await fetchOrders();
            setRatingModal({ show: true, orderId });
        } catch (err) {
            setError(err.message);
        }
    };

    const handleRating = async (orderId, rating, review) => {
        try {
            await rateOrder(orderId, rating, review);
            setRatingModal({ show: false, orderId: null });
            await fetchOrders();
        } catch (err) {
            setError(err.message);
        }
    };

    const handleComplaint = async (orderId, complaint) => {
        try {
            await fileComplaint(orderId, complaint);
            setComplaintModal({ show: false, orderId: null });
            await fetchOrders();
        } catch (err) {
            setError(err.message);
        }
    };

    const sampleOrders = {
        'vendor1-ref123': {
            vendorName: 'Sports Store',
            referenceId: 'ref123',
            status: 'PENDING',
            date: '2023-07-01T10:00:00Z',
            items: [
                {
                    id: 1,
                    name: 'Tennis Racket',
                    quantity: 1,
                    price: 149.99,
                    image: 'https://example.com/racket.jpg'
                },
                {
                    id: 2,
                    name: 'Tennis Balls (3-pack)',
                    quantity: 2,
                    price: 9.99,
                    image: 'https://example.com/balls.jpg'
                }
            ]
        },
        'vendor2-ref456': {
            vendorName: 'Gym Equipment',
            referenceId: 'ref456',
            status: 'READY',
            date: '2023-07-02T15:30:00Z',
            items: [
                {
                    id: 3,
                    name: 'Dumbbells Set',
                    quantity: 1,
                    price: 299.99,
                    image: 'https://example.com/dumbbells.jpg'
                }
            ]
        }
    };

    // For testing purposes
    // useEffect(() => {
    //     setOrders(sampleOrders);
    //     setLoading(false);
    // }, []);

    return (
        <React.Fragment>
            <PageHeader page="My Orders" />
            <div className="orders-container">
                {loading ? (
                    <div>Loading orders...</div>
                ) : error ? (
                    <div>{error}</div>
                ) : (
                    Object.values(orders).map((orderGroup) => (
                        <div key={orderGroup.referenceId} className="order-group">
                            <div className="order-header">
                                <p>#{orderGroup.referenceId}</p>
                                <span className={`status ${orderGroup.status.toLowerCase()}`}>
                                    {orderGroup.status}
                                </span>
                            </div>
                            <div className="vendor-info">
                                <p>{orderGroup.vendorName}</p>
                                <p>{new Date(orderGroup.date).toLocaleDateString()}</p>
                            </div>
                            <div className="order-items">
                                {orderGroup.items.map((item) => (
                                    <div key={item.id} className="order-item">
                                        <img src={item.imageUrl} alt={item.name} />
                                        <div className="item-details">
                                            <h5>{item.name}</h5>
                                            <p>Quantity: {item.quantity}</p>
                                            <p>Price: ${item.price}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="order-actions">
                                {orderGroup.status === 'PENDING' && orderGroup.ready === false && (
                                    <Button onClick={() => handleCancel(orderGroup.referenceId)}>
                                        <FontAwesomeIcon icon={faTimes} /> Cancel Order
                                    </Button>
                                )}
                                {orderGroup.ready === true && (
                                    <Button onClick={() => handleConfirm(orderGroup.referenceId)}>
                                        <FontAwesomeIcon icon={faCheck} /> Confirm Receipt
                                    </Button>
                                )}
                                <Button onClick={() => setComplaintModal({ show: true, orderId: orderGroup.referenceId })}>
                                    <FontAwesomeIcon icon={faWarning} /> File Complaint
                                </Button>
                            </div>
                        </div>
                    ))
                )}
            </div>

            {/* Rating Modal */}
            {ratingModal.show && (
                <div className="modal">
                    {/* Rating form implementation */}
                </div>
            )}

            {/* Complaint Modal */}
            {complaintModal.show && (
                <div className="modal">
                    {/* Complaint form implementation */}
                </div>
            )}
        </React.Fragment>
    );
};

export default Orders;