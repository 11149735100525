// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
button.loading {
  position: relative;
  padding-right: 40px;
}
button.loading .spinner {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}`, "",{"version":3,"sources":["webpack://./src/components/RegisterForm/Spinner.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,WAAA;EACA,YAAA;EACA,0CAAA;EACA,kBAAA;EACA,sBAAA;EACA,uCAAA;AACF;;AAEA;EACE;IAAK,yBAAA;EAEL;AACF;AAAA;EACE,kBAAA;EACA,mBAAA;AAEF;AAAE;EACE,kBAAA;EACA,WAAA;EACA,QAAA;EACA,2BAAA;AAEJ","sourcesContent":[".spinner {\n  display: inline-block;\n  width: 20px;\n  height: 20px;\n  border: 3px solid rgba(255,255,255,0.3);\n  border-radius: 50%;\n  border-top-color: #fff;\n  animation: spin 1s ease-in-out infinite;\n}\n\n@keyframes spin {\n  to { transform: rotate(360deg); }\n}\n\nbutton.loading {\n  position: relative;\n  padding-right: 40px;\n  \n  .spinner {\n    position: absolute;\n    right: 10px;\n    top: 50%;\n    transform: translateY(-50%);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
